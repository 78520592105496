<template>
  <div class="d-flex mr-4">
    <b-form-select v-model="selectedOption" :options="listSortOptions" @change="setKindSort"></b-form-select>
  </div>
</template>

<script>
import { BFormSelect } from 'bootstrap-vue';

export default {
  name: 'SelectSortOptions',
  components: {
    BFormSelect,
  },
  data() {
    return {
      selectedOption: '',
      listSortOptions: [
        { value: '', text: 'None' },
        { value: 'priceHTL', text: this.$t('search-result.priceLTH') },
        { value: 'priceLTH', text: this.$t('search-result.priceHTL') },
      ],
    };
  },
  methods: {
    setKindSort() {
      let kind = '', value = false;
      if (this.selectedOption.indexOf('price') > -1) {
        kind = 'price';
        value = this.selectedOption === 'priceLTH';
      }
      this.$emit('updateSort', [kind, value]);
    },
  },
};
</script>
<style scoped>
.list-inline-item:not(:last-child) {
  margin-left: 0.5rem;
  margin-right: 0;
}
.list-inline-item.active > label span {
  background-color: rgb(0, 97, 171);
  color: #fff;
}
.sort-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.33em;
  color: #313131;
  text-transform: capitalize;
  padding-right: 0;
  padding-left: 0;
  margin-bottom: 0;
}
.list-inline {
  margin-bottom: 0px !important;
}
label.lesiurTab {
  margin-bottom: 0px !important;
}
</style>
